import React, { useState } from "react";
import { observer } from "mobx-react";
import { inforStore } from "../InforAccountStore";
import Avatar from "react-avatar";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import { signUpStore } from "../../../auth/signUp/SignUpStore";
import Select from "../../../../common/component/Select";
import { urlImage } from "../../../../common/utils/Utils";
import { useTranslation } from "react-i18next";
import uploadFileHelper from "../../../../common/helpers/UploadImgHelper";

const DetailInfor = (): JSX.Element => {
  const { t } = useTranslation(["translation"], {
    keyPrefix: "profile",
  });

  const handleFilterDate = async (startDate: Date) => {
    inforStore.dataProfile.birthDay = startDate.getTime();
  };
  const [hasUploaded, setHasUploaded] = useState(false);
  const changeImg = (e: any) => {
    inforStore.dataProfile.avatar = e;
    setHasUploaded(true);
  };

  return (
    <div className="form-infor">
      <h1 className="form-infor-title">{t("accountInformation")}</h1>
      <h3 className="form-infor-sub-title">{t("title1")}</h3>
      <h5 className="form-infor-des-title">{t("title2")}</h5>

      <div className="form-content my-3">
        <div className="avatar-wrap" style={{ textAlign: "center" }}>
          <div className="mb-1 mt-3">
            <label htmlFor="upload-avatar">
              <div className="avatar-container">
                <Avatar
                  src={
                    !inforStore.dataProfile.avatar
                      ? "/assets/images/AvatarTemp.svg"
                      : (urlImage(
                          inforStore.dataProfile.avatar
                        ) as unknown as string)
                  }
                  size={150 as unknown as string}
                  round={true}
                  className={`${
                    !hasUploaded ? "sb-avatar--no-image" : ""
                  } avatar-image`}
                />
                <div className="overlay">
                  <img
                    src="/assets/images/Path 2496.svg"
                    alt=""
                    className="upload-icon object-fit-cover"
                  />
                </div>
              </div>
            </label>

            <input
              id="upload-avatar"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={async (e: any) =>
                await uploadFileHelper(e, changeImg)
              }
              style={{ display: "none" }}
            />
          </div>
          <span className="avatar-title">{t("avatar")}</span>
        </div>
        <div className="form-input-container">
          <div className="form-group">
            <div className="form-group">
              <label style={{ color: "rgb(171, 168, 168)" }}>
                {t("phoneNumber")}
              </label>
              <div className="input-group">
                <input
                  type="text"
                  disabled
                  readOnly
                  value={inforStore.dataProfile.phone}
                  className={`form-control form-control-lg ${
                    inforStore.errors.phone ? "border_error" : ""
                  }`}
                  placeholder={t("enterYourPhoneNumber")}
                />
              </div>
            </div>
            <div className="form-group">
              <label>
                {t("fullname")}
                <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  value={inforStore.dataProfile.name}
                  onChange={(e: any) =>
                    (inforStore.dataProfile.name = e.currentTarget.value)
                  }
                  className={`form-control form-control-lg ${
                    inforStore.errors.name ? "border_error" : ""
                  }`}
                  placeholder={t("enterYourFullname")}
                />
              </div>
              {inforStore.errors.name && (
                <p className="error">{inforStore.errors.name}</p>
              )}
            </div>

            <div className="form-group">
              <label>Email</label>
              <div className="input-group">
                <input
                  type="text"
                  value={inforStore.dataProfile.email}
                  onChange={(e: any) =>
                    (inforStore.dataProfile.email = e.currentTarget.value)
                  }
                  className={`form-control form-control-lg ${
                    inforStore.errors.email ? "border_error" : ""
                  }`}
                  placeholder={t("enterEmail")}
                />
              </div>
              {inforStore.errors && (
                <p className="error">{inforStore.errors.email}</p>
              )}
            </div>
            <div className="form-group">
              <label>{t("dateOfBirth")}</label>
              <DatePickerSingle
                locate="en"
                selected={inforStore.dataProfile.birthDay}
                onChange={handleFilterDate}
                placeholder={t("chooseDateOfBirth")}
                dateformat={"dd/MM/yyyy"}
              />
            </div>
            <div className="w-100">
              <div className="form-group">
                <label>{t("gender")}</label>
                <Select
                  value={inforStore.dataProfile.gender}
                  isShowError={false}
                  className="select-align-left"
                  onChange={(e: any) => {
                    inforStore.dataProfile.gender = e.currentTarget.value;
                  }}
                  placeholder={t("chooseGender")}
                  options={[
                    { id: "MALE", name: `${t("male")}` },
                    { id: "FEMALE", name: `${t("female")}` },
                  ]}
                />
                <p className="error">
                  {signUpStore.errors && signUpStore.errors.password}
                </p>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-save mt-2"
                onClick={async () => await inforStore.changeInfor()}
                disabled={inforStore.isLoadingBt}
              >
                {inforStore.isLoadingBt ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  `Lưu`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='login_footer_fixed'>
        © 2023 Ommani v.1.3
      </div>
    </div>
  );
};

export default observer(DetailInfor);
