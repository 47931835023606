import {toastUtil} from "../utils/ToastUtil";
import {getRequest} from "./RequestHelper";
import axios from "axios";
import HttpStatusCode from "../constants/HttpErrorCode";


async function uploadFileHelper(e: any, changeFile: any) {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]

    if (file.size > 10000 * 1024) {
        toastUtil.warning("Ảnh của bạn quá lớn. Vui lòng chọn ảnh có kích thước nhỏ hơn 10MB.", 1)
        return
    }
    reader.readAsDataURL(file);

    await uploadFile(file, changeFile)
}

let bucket = process.env.REACT_APP_BUCKET

const uploadFile = async (files: any, changeFile: any) => {
    let result = await getRequest(`/v1/s3/generate-upload-url?fileName=${files.name}&bucket=${bucket}&type=IMAGE`)

    if (result.status === HttpStatusCode.OK) {
        let dataFile = result.body

        const uploadFile = axios.create({
            baseURL: dataFile.uploadUrl,
            method: 'PUT',
            headers: {
                'Content-Type': files.type
            }
        })

        let resultGenFile = await uploadFile.put(dataFile.uploadUrl, files)

        if (resultGenFile?.status === HttpStatusCode.OK) {
            changeFile(dataFile.imagePath)
        }
    }
}


export default uploadFileHelper